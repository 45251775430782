
import { useState } from 'react';
import './App.css';
import Card from './components/Card';
import Team from './components/Team';
import BidForm from './components/BidForm';
import ActiveBids from './components/ActiveBids';

function App() {
  const [activePlayer, setActivePlayer] = useState(null);
  const [bids, setBids] = useState([]);


  const bid = (data) => {

    let { team, bidamount, teamid, available, used } = data;
    let allbids = [...bids];

    if (allbids.length < 1) {
      allbids.push({ team, bidamount, teamid, available, used });
    }
    else {
      for (let i = 0; i < allbids.length; i++) {

        if (allbids[i]["teamid"] === teamid) {
          allbids[i]["bidamount"] = bidamount;
          break;
        }
        else {
          let teamids = [];
          for (let j = 0; j < allbids.length; j++) {

            teamids.push(allbids[j]['teamid']);

          }
          if (teamids.indexOf(teamid) === -1) {
            allbids.push({ team, bidamount, teamid, available, used });
          }
        }

      }
    }
    setBids([...allbids])



  }

  const activeBidPlayer = (player) => {
    setActivePlayer(player)
  }

  return (
    <div>
      <div className="App max-w-screen-2xl mx-auto border border-t-0">

        <nav className="bg-white dark:bg-gray-900  w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
          <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4 px-8">
            <a href="https://flowbite.com/" className="flex items-center space-x-3 rtl:space-x-reverse">

              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Mystifly Premier Leauge(MPL) Auction</span>
            </a>

            <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
              <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <a href="/" className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">23 May 2024 : 5:30PM</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>



        <div className='grid grid-cols-2 h-screen'>
          <div >
            <div className='border-r border-b h-[350px]'>
              <div className=' bg-slate-900 '> <Card activeBidPlayer={activeBidPlayer} /></div>
            </div>
            <div className='border-r h-[350px]'>
              <Team />
            </div>
          </div>
          <div>
            <div className='border-b h-[350px]'>
              <ActiveBids activePlayer={activePlayer} bids={bids} />
            </div>
            <div className='h-[350px]'>
              {activeBidPlayer !== null ? <BidForm bid={bid} /> : null}

            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default App;
