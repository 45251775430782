import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';

const BidForm = (props) => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            const supabaseUrl = 'https://zhwsjpfwnnqntcbtzivu.supabase.co'
            const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpod3NqcGZ3bm5xbnRjYnR6aXZ1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYzMTE0NjUsImV4cCI6MjAzMTg4NzQ2NX0.0cGn5Sn4jeNsTccyY7v1tuLJfbU2EfQqpSG6Q9mha8M"
            const supabase = createClient(supabaseUrl, supabaseKey)

            let { data: teams, error } = await supabase
                .from('teams')
                .select('*')

            if (teams) {

                setTeams([...teams])
            }
            else {
                console.log(error)
            }

        }
        fetchData();

    }, [])
    return (
        <div className='p-6'>
            {
                teams.length > 0 ? <div className='grid grid-cols-3 gap-3'>

                    {
                        teams.map((team, i) => {
                            return (
                                <div key={i} className='m-3'>
                                    <label className='block'>{team.name}</label>
                                    <input type='number' placeholder='Enter bid amount'
                                        disabled={team.available < 1 ? true : false}

                                        className={`border p-2 w-full rounded ${team.available < 1 ? "border-red-400 bg-red-200" : ""}`}
                                        onChange={(e) => { props.bid({ team: team.name, bidamount: Number(e.target.value), teamid: team.id, available: team.available, used: team.used }) }}

                                    />
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}

export default BidForm