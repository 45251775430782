import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { createClient } from '@supabase/supabase-js';
import Confetti from './Confetti';
const ActiveBids = (props) => {

    const [highestbid, setHighestBid] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confetti, setConfetti] = useState(false);


    const onSold = async () => {
        setLoading(true);

        const supabaseUrl = 'https://zhwsjpfwnnqntcbtzivu.supabase.co'
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpod3NqcGZ3bm5xbnRjYnR6aXZ1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYzMTE0NjUsImV4cCI6MjAzMTg4NzQ2NX0.0cGn5Sn4jeNsTccyY7v1tuLJfbU2EfQqpSG6Q9mha8M"
        const supabase = createClient(supabaseUrl, supabaseKey)


        const { id } = props.activePlayer
        const { data, error } = await supabase
            .from('players')
            .update({ auctioned: true, teamname: highestbid.team, teamid: highestbid.teamid, bidamount: highestbid.bidamount })
            .eq('id', id)
            .select()

        // console.log(highestbid.teamid);
        if (data.length > 0) {

            const updateTeams = async () => {
                let available = highestbid.available;
                let used = highestbid.used;
                let bidamount = highestbid.bidamount;

                let availableNew = available - bidamount;
                let usednew = used + bidamount;


                const { data: team, newerror } = await supabase
                    .from('teams')
                    .update({ available: availableNew, used: usednew })
                    .eq('id', highestbid.teamid)
                    .select()


                if (team.length > 0) {
                    setConfetti(true)
                    setTimeout(() => {
                        setLoading(false)
                        window.location.reload()
                    }, 3500)
                }
                else {
                    console.log(newerror)
                    alert('Error updating teams')
                }
            }
            updateTeams();



        }
        else {
            console.log(error)
            alert('Unable to update bid status');
        }



    }


    const onUnSold = async () => {

        setLoading(true);

        const supabaseUrl = 'https://zhwsjpfwnnqntcbtzivu.supabase.co'
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpod3NqcGZ3bm5xbnRjYnR6aXZ1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYzMTE0NjUsImV4cCI6MjAzMTg4NzQ2NX0.0cGn5Sn4jeNsTccyY7v1tuLJfbU2EfQqpSG6Q9mha8M"
        const supabase = createClient(supabaseUrl, supabaseKey);


        const { id } = props.activePlayer
        const { data, error } = await supabase
            .from('players')
            .update({ auctioned: true })
            .eq('id', id)
            .select();

        if (data.length > 0) {
            setLoading(false);
            setTimeout(function () {
                window.location.reload();
            }, 500)
        }
        else {
            console.log(error)
        }

    }
    useEffect(() => {


        const sorted = props.bids.sort((a, b) => b.bidamount - a.bidamount);
        setHighestBid(sorted[0])

    }, [props.bids]);


    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <p>Done</p>;
        } else {
            // Render a countdown
            return <span className='text-3xl'>0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
        }
    };



    return (

        <>
            {
                confetti ? <div className='fixed w-[50%]'>
                    <Confetti />
                </div> : null
            }

            {
                highestbid ?
                    <div className='text-center p-10 h-[269px]'>
                        <p>Highest bid by</p>
                        <div >
                            <span className='text-3xl inline-block font-semibold border p-3 border-green-500 my-3 rounded'>#{highestbid.team}</span>
                            <br />
                            <span className='text-3xl inline-block text-green-800 bg-green-200 p-2 rounded font-extrabold animate-bounce'>{highestbid.bidamount}</span>
                        </div>
                    </div>
                    :
                    <div className='text-center p-16 h-[269px] text-3xl text-gray-400'><p>#nobids</p></div>}

            <div className='grid grid-cols-2 border-t'>
                <div className='w-100px mx-auto mt-4'>
                    <Countdown date={Date.now() + 120000} renderer={renderer} precision={3} />
                </div>
                <div className='text-center grid grid-cols-2 gap-3 p-5 border-l'>
                    <button type='button' className="bg-red-600 text-white py-2 px-6 rounded"
                        onClick={onUnSold}>{loading ? "Please wait" : "UNSOLD"}</button>
                    <button type='button' className="bg-green-600 text-white py-2 px-6 rounded" onClick={onSold}>{loading ? "Please wait" : "SOLD"}</button>
                </div>
            </div>
        </>
    )

}

export default ActiveBids