import React, { useEffect, useState } from 'react'
import { createClient } from '@supabase/supabase-js';

const Card = (props) => {

    const [players, setPlayer] = useState(null);


    useEffect(() => {

        const fetchData = async () => {
            const supabaseUrl = 'https://zhwsjpfwnnqntcbtzivu.supabase.co'
            const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpod3NqcGZ3bm5xbnRjYnR6aXZ1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYzMTE0NjUsImV4cCI6MjAzMTg4NzQ2NX0.0cGn5Sn4jeNsTccyY7v1tuLJfbU2EfQqpSG6Q9mha8M"
            const supabase = createClient(supabaseUrl, supabaseKey)

            let { data: players, error } = await supabase
                .from('players')
                .select('*')
                .order('name');

            if (players) {

                for (let i = 0; i < players.length; i++) {
                    if (!players[i]["auctioned"]) {
                        setPlayer(players[i]);
                        props.activeBidPlayer(players[i])
                        break;
                    }
                }

            }
            else {
                console.log(error)
            }

        }
        fetchData();

    }, [])



    if (players === null) {
        return (<p>No player Available</p>);
    }
    return (
        <div className="  w-full  flex-col rounded-xl bg-transparent bg-clip-border text-gray-700 shadow-none  z-40 relative bg-slate-800">
            <div
                className="w-full elative  pt-0  mx-0 overflow-hidden text-gray-700 bg-transparent shadow-none bg-clip-border bg-contain bg-no-repeat  h-[350px] bg-top bg-slate-900"
                style={{ backgroundImage: `URL(${players.picture})` }}

            >


                {/* <img
                    src={players.picture}
                    alt="Tania Andrew"
                    className="relative inline-block w-[200px] -mr-4 " /> */}
                <div className="absolute bottom-0 w-full">

                    <h5
                        className=" bg-opacity-80 px-3 block font-sans text-3xl antialiased font-bold leading-snug tracking-normal text-blue-gray-900 uppercase bg-slate-800 text-white rounded-tr-lg pt-4 pl-6">
                        {players.name}

                    </h5>
                    <p className="bg-slate-800 text-white bg-opacity-80 px-3  font-sans text-lg font-medium antialiased  leading-relaxed text-blue-gray-900 text-left rounded-br-lg  pl-6">
                        <span className='block text-base pb-2'>About player : {players.description}</span>



                        <p className='pb-4'>
                            <span className='text-sm font-medium mr-2'>Player Type : <span className='text-green-400'>({players.type.replace(';', '')})</span></span>
                            <span className='text-sm mr-2'>Playing frequency : <span className='text-green-400'>{players.frequency}</span></span>
                            <span className='text-sm mr-2'>Batting Pref : <span className='text-green-400'>{players.battype}</span></span>
                            <span className='text-sm '>Bowling Pref : <span className='text-green-400'>{players.bowltype}</span></span>

                        </p>


                    </p>



                </div>
            </div>

        </div>
    )
}

export default Card