import React, { useEffect, useState } from 'react'
import { createClient } from '@supabase/supabase-js';



const Team = () => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            const supabaseUrl = 'https://zhwsjpfwnnqntcbtzivu.supabase.co'
            const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpod3NqcGZ3bm5xbnRjYnR6aXZ1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYzMTE0NjUsImV4cCI6MjAzMTg4NzQ2NX0.0cGn5Sn4jeNsTccyY7v1tuLJfbU2EfQqpSG6Q9mha8M"
            const supabase = createClient(supabaseUrl, supabaseKey)

            let { data: teams, error } = await supabase
                .from('teams')
                .select('*')

            if (teams) {
                setTeams([...teams])
            }

            console.log(error);

        }
        fetchData();

    }, [])


    return (
        <div>

            {
                teams.length > 0 ? <>
                    <table className='w-full'>
                        <thead>
                            <tr>
                                <th className='text-left bg-indigo-100 p-2 py-3 pl-8'>Team</th>
                                <th className='text-center bg-indigo-100 p-2 py-3'>Available budget</th>
                                <th className='text-center bg-indigo-100 p-2 py-3'>Used Budget</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                teams.map((team, i) => (
                                    <tr key={i} className={`${i % 2 === 0 ? "bg-slate-50" : ""}`}>
                                        <td className='px-2 py-2 border pl-8'>{team.name}</td>
                                        <td className='text-center px-2 py-2 border text-green-700'>{team.available}</td>
                                        <td className='text-center px-2 py-2 border text-red-500'>{team.used}</td>
                                    </tr>))
                            }

                        </tbody>
                    </table>
                </> : null
            }

        </div>
    )
}

export default Team